import React from "react"
import Hero from "../components/hero-ff"
import FFDetails from "../components/financialforce-details"

import "../assets/index.css"
import Layout from "../layouts/layout"

export default () => (
    <Layout title="Certinia (Formally FinancialForce) | Ladd Partners">
        <Hero/>
        <FFDetails/>
    </Layout>
)
